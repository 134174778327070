@import '../../variables.css';

#Checkout {
  padding: 50px 0;
}
#Checkout .ant-card {
  width: 1000px;
  margin: auto;
}
#Checkout .ant-card .ant-card-body .project-name {
  width: 500px;
  margin: auto;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
#Checkout .ant-card .ant-card-body .detail-client {
  padding: 0 30px;
  margin-bottom: 20px;
}
#Checkout .ant-card .ant-card-body .detail-client .invoice-info-created {
  text-align: right;
}
#Checkout
  .ant-card
  .ant-card-body
  .detail-client
  .invoice-info-created
  .item-info {
  display: flex;
  justify-content: space-between;
  width: 270px;
  margin-left: auto;
}

#Checkout .ant-card .ant-card-body .invoice-overview {
  padding: 0 30px;
  margin-bottom: 20px;
  width: 450px;
  margin-left: auto;
}
#Checkout .ant-card .ant-card-body .invoice-overview .item-overview {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: 1px solid #919aa9;
  padding: 10px;
}
#Checkout
  .ant-card
  .ant-card-body
  .invoice-overview
  .item-overview.total-tagihan {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffdbcb;
  border-bottom: 1px solid #ff7e45;
  padding: 10px;
}

#Checkout .ant-card .ant-card-body .product-invoice {
  padding: 0 30px;
  margin-bottom: 20px;
}

#Checkout .ant-card .ant-card-body .detail-info {
  padding: 0 30px;
}

#Checkout .ant-card .ant-card-body .detail-info span.site-policy {
  color: #fa530a;
}
