@import '~antd/dist/antd.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body>iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  background-color: white;
}

.ant-input {
  border-radius: 5px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 10px 10px 0;
}

.ant-input-number-affix-wrapper {
  border-radius: 5px;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffcdb7;
  color: #fa530a !important;
  border-left: 3px solid #fa530a;
  font-weight: bold;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>svg>path {
  fill: #fa530a !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>svg>g path {
  fill: #fa530a !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: unset;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #fa530a;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  color: #989898;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>svg>path {
  fill: #989898;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>svg>g path {
  fill: #989898;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: var(--color-secondary);
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon+span {
  display: none;
}

.ant-btn {
  border-radius: 5px;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.ant-btn:hover {
  color: white;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-btn-link {
  padding: unset;
  border-color: unset;
  border: unset;
  color: var(--color-primary);
}

.ant-btn-link:hover {
  background-color: white;
  color: var(--color-primary);
}

.ant-btn-link:focus {
  color: var(--color-primary);
}

.ant-btn-primary {
  background-color: var(--color-primary);
  color: white;
  border-radius: 5px;
  border-color: var(--color-primary);
}

.ant-btn-primary:hover {
  background-color: white;
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-btn-primary:focus {
  background-color: white;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
  border-radius: 10px 0 0 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 30px;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 10px;
  padding: 10px 20px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  margin-bottom: 10px;
}