:root {
  --color-primary: #FA530A;
  --color-secondary: #FFE2D6;
  --color-third: #FFF4EF;

  --color-success: #45BB0D;
  --color-warning: #ffa700;
  --color-danger: #d62d20;

  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-20: 1.25rem;
  --font-size-21: 1.3125rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-25: 1.5625rem;
  --font-size-32: 2rem;

  --box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
}
