#CardDataTable .ant-card {
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: var(--box-shadow-base);
  background-color: var(--color-third);
}

#CardDataTable .ant-card .ant-card-head {
  border: unset;
}

#CardDataTable .ant-card .ant-card-head .ant-card-head-title > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#CardDataTable .ant-card .ant-card-head .ant-card-head-title h4.ant-typography {
  margin: unset;
}

#CardDataTable .ant-card .ant-card-head .ant-card-head-title .action-header {
  display: flex;
}
#CardDataTable .ant-card .ant-card-head .ant-card-head-title .action-header > div {
  margin-left: 10px;
}
#CardDataTable .ant-card .ant-card-head .ant-card-head-title .action-header > div > .ant-input-affix-wrapper {
  border-radius: 10px;
}
