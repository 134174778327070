#AdminInvoice .search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#AdminInvoice .content-dashboard {
  margin-top: 20px;
}

#AdminInvoice .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fa530a;
}
#AdminInvoice .ant-tabs-tab:hover {
  color: #fa530a;
}
#AdminInvoice .ant-tabs-ink-bar {
  background: #fa530a;
}
