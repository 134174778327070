#CheckoutPaid .ant-card {
  width: 800px;
  margin: auto;
  margin-top: 60px;
}
#CheckoutPaid .ant-card .ant-card-body {
  padding: 0;
}
#CheckoutPaid .ant-card .ant-card-body .section-content {
  display: flex;
  justify-content: space-between;
}
