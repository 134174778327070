.headline-menu .ant-image {
  width: 80px;
  margin-bottom: 10px;
  padding-right: 10px;
}

.sticky-toc {
  width: 220px;
  min-width: 220px;
  margin-top: 10px;
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  max-height: calc(100vh - 70px);
  overflow: auto;
}

.ant-typography pre,
li {
  padding: 0 !important;
  word-wrap: unset !important;
  background: unset !important;
  border: unset !important;
  border-radius: unset !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Poppins' !important;
  font-size: 1.15em !important;
}
