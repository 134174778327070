#DataTable .loading-table {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
}
#DataTable .ant-table {
  font-size: 14px;
}

#DataTable .ant-pagination-item {
  border-radius: 25px;
  font-size: 12px;
  min-width: 25px;
  height: 25px;
  line-height: 22px;
}

#DataTable .ant-pagination-prev {
  min-width: 25px;
  height: 25px;
  line-height: 22px;
}
#DataTable .ant-pagination-next {
  min-width: 25px;
  height: 25px;
  line-height: 22px;
}
#DataTable .ant-pagination-jump-prev {
  min-width: 25px;
  height: 25px;
  line-height: 22px;
}
#DataTable .ant-pagination-jump-next {
  min-width: 25px;
  height: 25px;
  line-height: 22px;
}

#DataTable .ant-table-thead {
  background-color: var(--color-secondary);
}
#DataTable .ant-table-thead > tr > th {
  background: var(--color-secondary);
  border-bottom: 4px solid var(--color-primary);
  font-weight: bold;
  color: #000;
  /* white-space: nowrap; */
}

#DataTable .ant-table-cell-fix-left {
  background: #fff;
}

#DataTable .ant-table-column-sorters{
  padding-top: 10px;
}

#DataTable .ant-table-tbody > tr > td {
  padding: 4px 16px;
}

#DataTable .table-row-light {
  background-color: #ffffff;
}
#DataTable .table-row-dark {
  background-color: #f8f8f8;
}

#DataTable .styleFree .ant-table-tbody > tr > td {
  padding: unset;
}

#DataTable .ant-table-column-sorter-up.active {
  color: var(--color-primary);
}
#DataTable .ant-table-column-sorter-down.active {
  color: var(--color-primary);
}

#DataTable .ant-pagination-item-active {
  border-color: var(--color-primary);
}
#DataTable .ant-pagination-item-active a {
  color: var(--color-primary);
}
#DataTable .ant-pagination-options {
  position: absolute;
  left: 0;
  margin: unset;
}
#DataTable .action-table .ant-btn {
  margin-left: 10px;
}
