@import '../../variables.css';

#AuthLayout {
  min-height: 100vh;
}

#AuthLayout .ant-layout-footer {
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  background: transparent;
  bottom: 0;
  width: 100%;
  color: var(--color-primary);
}
#AuthLayout .ant-layout-footer .ant-typography {
  color: var(--color-primary);
}

#AuthLayout .ant-layout-footer .ant-typography {
  font-size: var(--font-size-12);
}

#authForm {
  min-height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#authForm .auth-logo {
  display: flex;
  align-items: flex-end;
  margin: 20px auto 40px auto;
}
#authForm .auth-logo .img-logo {
  width: 120px;
  height: 80px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
#authForm .auth-logo h2.ant-typography {
  margin: unset;
  line-height: 25px;
  font-weight: bold;
}
#authForm h4.ant-typography {
  font-weight: bold;
  margin-top: 10px;
}

#authForm .ant-card {
  width: 500px;
  height: fit-content;
  background-color: var(--color-secondary);
  border-radius: 30px;
  box-shadow: var(--box-shadow-base);
  margin: unset;
}

#authForm .ant-card .ant-card-body {
  padding: 30px 20px;
}

#authForm .ant-card .ant-card-body .section-form {
  justify-content: center;
  width: 100%;
  height: fit-content;
  padding: 20px 40px;
}

#authForm .ant-card .ant-card-body .section-form h5.ant-typography {
  color: var(--color-primary);
}

#authForm .ant-card .ant-card-body .section-form .form-auth {
  margin-top: 30px;
  width: 100%;
}
#authForm .ant-card .ant-card-body .section-form .form-auth .extra-btn-pwd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
#authForm
  .ant-card
  .ant-card-body
  .section-form
  .form-auth
  .extra-btn-pwd
  > label
  > span {
  color: var(--color-primary);
}
#authForm
  .ant-card
  .ant-card-body
  .section-form
  .form-auth
  .extra-btn-pwd
  > .ant-btn
  > span {
  color: var(--color-primary);
}
#authForm
  .ant-card
  .ant-card-body
  .section-form
  .form-auth
  .extra-btn-pwd
  .btn-forgot {
  padding: 0;
}
#authForm
  .ant-card
  .ant-card-body
  .section-form
  .form-auth
  .ant-form-item-control-input-content {
  text-align: center;
}
#authForm
  .ant-card
  .ant-card-body
  .section-form
  .form-auth
  .ant-form-item-control-input-content
  > .ant-btn {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  font-weight: bold;
  width: 120px;
  height: 50px;
  border-radius: 10px;
}
#authForm .ant-card .ant-card-body .auth-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 350px;
}
#authForm .ant-card .ant-card-body .auth-image .img-landing {
  width: 100%;
}

span.site-policy:hover {
  text-decoration: underline;
}
