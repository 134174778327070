#DetailInvoiceOpen{

}

#DetailInvoiceOpen .headerContainer{
    margin-top: 36;
}

#DetailInvoiceOpen .billTo{
    margin-top: 20;
    padding-bottom: 3;
    font-family: 'Helvetica-Oblique';
    font-weight: 'bold';
    max-width: 280;
}

#DetailInvoiceOpen .address{
    font-family: 'Helvetica-Oblique';
    max-width: 280;
}

#DetailInvoiceOpen .va{
    color: #FA530A;
}

#DetailInvoiceOpen .button-action-open .ant-btn{
    margin-right: 10px;
    color: #E94444;
    border-color: #E94444;
}
#DetailInvoiceOpen .button-action-paid .ant-btn{
    margin-right: 10px;
    color: #2E8405;
    border-color: #2E8405;
}
#DetailInvoiceOpen .button-action-closed .ant-btn{
    margin-right: 10px;
    color: #1F62C7;
    border-color: #1F62C7;
}
#DetailInvoiceOpen .button-action-void .ant-btn{
    margin-right: 10px;
    color: #000000;
    border-color: #000000;
}
#DetailInvoiceOpen .button-action-draft .ant-btn{
    margin-right: 10px;
    color: #B99515;
    border-color: #B99515;
}

#DetailInvoiceOpen .inv-overview {
  margin-top: 30px;
  min-width: 300px;
  max-width: 400px;
}
#DetailInvoiceOpen .inv-overview .item-overview {
  border-bottom: 1px solid rgb(193 193 193);
  padding: 10px 20px;
}
#DetailInvoiceOpen .inv-overview .item-overview.total {
  background-color:rgb(193 193 193);
  border-bottom: 1px solid rgb(193 193 193);
}

#DetailInvoiceOpen .ant-form-item {
  margin-bottom: 15px;
}
#DetailInvoiceOpen .section-field {
  margin-bottom: 20px;
}
#DetailInvoiceOpen .card-tambah-product {
  margin-top: 20px;
}
#DetailInvoiceOpen .inv-detail {
  /* margin-bottom: 20px;
  margin-top: 20px; */
}
#DetailInvoiceOpen .inv-detail .content-inv-detail {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
#DetailInvoiceOpen .inv-detail .content-inv-detail .ant-row {
  display: flex;
  flex-flow: nowrap;
}
#DetailInvoiceOpen .inv-detail .content-inv-detail .ant-row .ppn-pph {
  text-align: center;
}
#DetailInvoiceOpen .inv-detail .content-inv-detail .ant-row .ant-col .ppn-pph {
  text-align: center;
}
#DetailInvoiceOpen .inv-detail .content-inv-detail .ant-row .ant-col .ant-form-item {
  margin-bottom: 10px;
}

#DetailInvoiceOpen .recuring-data .section-field .ant-collapse {
  border-color: rgba(255, 126, 69, 0.5);
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item {
  border-color: rgba(255, 126, 69, 0.5);
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item .ant-collapse-header {
  border-color: rgba(255, 126, 69, 0.5);
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item .ant-collapse-content {
  border-color: rgba(255, 126, 69, 0.5);
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item .ant-collapse-content .item-recuring .status-badges-draft {
  padding: 5px 20px;
  border: 2px solid #787878;
  color: #787878;
  width: fit-content;
  font-weight: 600;
  margin-left: auto;
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item .ant-collapse-content .item-recuring .status-badges-open {
  padding: 5px 20px;
  border: 2px solid #E94444;
  color: #E94444;
  width: fit-content;
  font-weight: 600;
  margin-left: auto;
}
#DetailInvoiceOpen .recuring-data .section-field .ant-collapse .ant-collapse-item .ant-collapse-content .item-recuring .status-badges-paid {
  padding: 5px 20px;
  border: 2px solid #2E8405;
  color: #2E8405;
  width: fit-content;
  font-weight: 600;
  margin-left: auto;
}
