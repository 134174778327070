#DetailInvoiceSend{

}

#DetailInvoiceSend .headerContainer{
    margin-top: 36;
    font-family: 'Poppins';
}

#DetailInvoiceSend .boxData{
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-self: center;
    align-content: center;
    align-items: center;
    padding: 16px;
    gap: 40px;
    margin: auto;
    margin-bottom: 20px;
    width: 400px;
    height: 110px;

    border: 2px solid rgba(255, 126, 69, 0.55);
    flex: none;
    order: 1;
    flex-grow: 0;
}

#DetailInvoiceSend .rowData{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 41px;
    width: 100%;
    height: 18px;
}

#DetailInvoiceSend .row{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 41px;
    width: 100%;
    height: 18px;
}

#DetailInvoiceSend .label{
    width: 100px;
    height: 18px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    /* identical to box height */


    color: #000000;
}
#DetailInvoiceSend .formLabel{
    width: 300px;
    height: 18px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #000000;
}

#DetailInvoiceSend .value{
    width: 220px;
    height: 14px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: left;

    color: #000000;
}

#DetailInvoiceSend .frame{
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px;
    gap: 8px;

    width: 100%;
    height: 78px;
}

#DetailInvoiceSend .line{
    width: 736px;
    height: 0px;

    border: 0.5px solid #000000;
}
#DetailInvoiceSend .rincian{
    /* LIhat Rincian Tagihan */

    width: 128px;
    height: 18px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    text-align: center;
    text-decoration-line: underline;

    color: #FA530A;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;


}

#DetailInvoiceSend .billTo{
    margin-top: 20;
    padding-bottom: 3;
    font-family: 'Helvetica-Oblique';
    font-weight: 'bold';
    max-width: 280;
}

#DetailInvoiceSend .address{
    font-family: 'Helvetica-Oblique';
    max-width: 280;
}

#DetailInvoiceSend .va{
    color: #FA530A;
}

#DetailInvoiceSend .inv-overview {
    margin-right: 400;
    margin-top: 30px;
    min-width: 300px;
    max-width: 400px;
    /* position: absolute; */
    right: 15px;
    padding-right: 400;
    padding-top: 10;
    padding-bottom: 10;
}
#DetailInvoiceSend .inv-overview .item-overview {
    border-bottom: 1px solid rgb(193 193 193);
    padding: 10px 20px;
}
#DetailInvoiceSend .inv-overview .item-overview.total {
    background-color:rgb(255, 219, 203, 1);
    border-bottom: 1px solid rgb(255, 126, 69, 1);
}

#DetailInvoiceSend .button-action-open .ant-btn{
    margin-right: 10px;
    color: #E94444;
    border-color: #E94444;
}
#DetailInvoiceSend .button-action-paid .ant-btn{
    margin-right: 10px;
    color: #2E8405;
    border-color: #2E8405;
}
#DetailInvoiceSend .button-action-closed .ant-btn{
    margin-right: 10px;
    color: #1F62C7;
    border-color: #1F62C7;
}
#DetailInvoiceSend .button-action-void .ant-btn{
    margin-right: 10px;
    color: #000000;
    border-color: #000000;
}
#DetailInvoiceSend .button-action-draft .ant-btn{
    margin-right: 10px;
    color: #B99515;
    border-color: #B99515;
}

#DetailInvoiceSend .preview-file-pdf {
  height: 500px;
  padding: 20px 40px;
  overflow-y: auto;
  background-color: #dddddd;
  border-radius: 10px;
}
#DetailInvoiceSend .preview-file-pdf .preview-content {
  background-color: white;
  box-shadow: var(--box-shadow-base);
  padding: 20px 30px;
  width: 210mm;
  height: 297mm;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
#DetailInvoiceSend .ant-form-item {
  margin-bottom: 15px;
}
#DetailInvoiceSend .section-field {
  margin-bottom: 20px;
}
