#DetailInvoiceDraft{

}

#DetailInvoiceDraft .va{
    color: #FA530A;
}

#DetailInvoiceDraft .button-action-open .ant-btn{
    margin-right: 10px;
    color: #E94444;
    border-color: #E94444;
}
#DetailInvoiceDraft .button-action-paid .ant-btn{
    margin-right: 10px;
    color: #2E8405;
    border-color: #2E8405;
}
#DetailInvoiceDraft .button-action-closed .ant-btn{
    margin-right: 10px;
    color: #1F62C7;
    border-color: #1F62C7;
}
#DetailInvoiceDraft .button-action-void .ant-btn{
    margin-right: 10px;
    color: #000000;
    border-color: #000000;
}
#DetailInvoiceDraft .button-action-draft .ant-btn{
    margin-right: 10px;
    color: #B99515;
    border-color: #B99515;
}

#DetailInvoiceDraft .inv-overview {
    margin-top: 30px;
    min-width: 300px;
    max-width: 400px;
}
#DetailInvoiceDraft .inv-overview .item-overview {
    border-bottom: 1px solid rgb(193 193 193);
    padding: 10px 20px;
}
#DetailInvoiceDraft .inv-overview .item-overview.total {
    background-color:rgb(193 193 193);
    border-bottom: 1px solid rgb(193 193 193);
}

#DetailInvoiceDraft .button-action {
    margin-top: 30px;
}
#DetailInvoiceDraft .button-action .ant-btn{
    margin-right: 10px;
}

#DetailInvoiceDraft .inv-identity {
    min-width: 300px;
    max-width: 700px;
}
#DetailInvoiceDraft .inv-client {
    min-width: 300px;
    max-width: 700px;
}
#DetailInvoiceDraft .selection-type-invoice {
    min-width: 300px;
    max-width: 700px;
}
#DetailInvoiceDraft .inv-overview {
    margin-top: 30px;
    min-width: 300px;
    max-width: 400px;
}
#DetailInvoiceDraft .inv-overview .item-overview {
    border-bottom: 1px solid rgb(193 193 193);
    padding: 10px 20px;
}
#DetailInvoiceDraft .inv-overview .item-overview.total {
    background-color:rgb(193 193 193);
    border-bottom: 1px solid rgb(193 193 193);
}

#DetailInvoiceDraft .inv-detail {
    margin-bottom: 20px;
}
#DetailInvoiceDraft .inv-detail .content-inv-detail {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}
#DetailInvoiceDraft .inv-detail .content-inv-detail .ant-row {
    display: flex;
    flex-flow: nowrap;
}
#DetailInvoiceDraft .inv-detail .content-inv-detail .ant-row .ppn-pph {
    text-align: center;
}
#DetailInvoiceDraft .inv-detail .content-inv-detail .ant-row .ant-col .ppn-pph {
    text-align: center;
}
#DetailInvoiceDraft .inv-detail .content-inv-detail .ant-row .ant-col .ant-form-item {
    margin-bottom: 10px;
}
#DetailInvoiceDraft .inv-signature {
    min-width: 300px;
    max-width: 700px;
}
#DetailInvoiceDraft .inv-attachments {
    min-width: 300px;
    max-width: 700px;
}

#DetailInvoiceDraft .ant-form-item {
  margin-bottom: 15px;
}
#DetailInvoiceDraft .section-field {
  margin-bottom: 20px;
}
