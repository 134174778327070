@import '../../../variables.css';

#SideMenu {
  background-color: var(--color-secondary);
  /* height: calc(100vh); */
  height: 90%;
  border-right: 1px solid #f0f0f0;
  position: fixed;
  z-index: 1;
  border-radius: 0 20px 20px 0;
  margin-top: 30px;
}
#SideMenu .ant-layout-sider-children {
  padding: 20px 0;
}
#SideMenu .ant-layout-sider-trigger {
  bottom: auto;
  border-radius: 0 0 20px 0;
}

#SideMenu .headline-menu {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 30px;
}
#SideMenu .headline-menu .ant-image {
  width: 70px;
}
#SideMenu .headline-menu h4.ant-typography {
  margin: unset;
  line-height: 25px;
  font-weight: bold;
}

#SideMenu .user-info {
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-bottom: 30px;
}
#SideMenu .user-info .ant-btn {
  border-radius: 10px;
  height: 40px;
  width: fit-content;
  min-width: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#SideMenu .user-info .ant-btn:hover > svg {
  fill: var(--color-primary);
}
#SideMenu .user-info .ant-btn:hover > svg > path {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
#SideMenu .ant-avatar {
  margin-bottom: 10px;
  background-color: grey;
}

#SideMenu .ant-menu {
  padding-left: 10px;
}
#SideMenu .ant-menu-inline {
  border: unset;
  background-color: var(--color-secondary);
}
#SideMenu .ant-menu-vertical {
  border: unset;
  background-color: var(--color-secondary);
}
#SideMenu .ant-menu-vertical .ant-menu-item{
  padding: 5px 22px
}
#SideMenu .ant-menu-inline.ant-menu-root .ant-menu-item > * {
  font-size: 16px;
}
#SideMenu .ant-menu-inline.ant-menu-root .ant-menu-item > span {
  margin-left: 10px;
}
#SideMenu .action-user {
  position: absolute;
  bottom: 50px;
  width: 100%;
}
#SideMenu .action-user .btn-logout {
  justify-content: space-around;
  width: fit-content;
  margin: auto;
  color: var(--color-primary);
  border-color: var(--color-primary);
  border: 2px solid var(--color-primary);
  padding: 5px;
  height: 35px;
  border-radius: 10px;
  font-weight: bold;
  background-color: white;
  display: flex;
}
#SideMenu .action-user .btn-logout:hover {
  background-color: var(--color-primary);
  color: white;
}
#SideMenu .action-user .btn-logout > span {
  margin-left: 10px;
}
#SideMenu .action-user .btn-logout > svg {
  fill: var(--color-primary);
}
#SideMenu .action-user .btn-logout > svg > path {
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
#SideMenu .action-user .btn-logout:hover > svg {
  fill: white;
}
#SideMenu .action-user .btn-logout:hover > svg > path {
  fill: white;
  stroke: white;
}
#SideMenu .ant-layout-sider-trigger {
  background-color: var(--color-secondary);
  color: #fa530a;
  height: 30px;
  line-height: 30px;
}
#SideMenu .footer {
  margin-top: 20px;
  font-size: 9px;
  text-align: center;
}
#SideMenu .footer > .ant-typography {
  color: var(--color-primary);
}

@media (max-height: 600px) {
  #SideMenu {
    flex: 0 0 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    width: 200px !important;
  }
  #SideMenu .headline-menu {
    margin-bottom: 20px;
  }
  #SideMenu .headline-menu .ant-image {
    width: 50px;
  }
  #SideMenu .headline-menu h4.ant-typography {
    margin: unset;
    line-height: 18px;
    font-weight: bold;
    font-size: 14px;
  }
  #SideMenu .user-info {
    margin-bottom: 20px;
  }
  #SideMenu .ant-menu-inline.ant-menu-root .ant-menu-item > * {
    font-size: 14px;
  }
  .ant-menu-inline > .ant-menu-item {
    height: 30px;
  }
  #SideMenu .footer {
    margin-top: 10px;
    font-size: 9px;
    text-align: center;
  }
  #SideMenu .action-user .btn-logout {
    font-size: 12px;
  }
  #SideMenu .ant-layout-sider-children {
    padding: 10px 0px;
  }
  #SideMenu .ant-layout-sider-trigger {
    width: 200px !important;
  }
  #AppLayout {
    position: relative;
    margin-left: 210px !important;
  }
}
