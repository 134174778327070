@import '../../../variables.css';

#AppLayout {
  position: relative;
  margin-left: 280px;
}
#AppLayout .ant-layout-header {
  margin-top: 20px;
  background-color: transparent;
  line-height: normal;
  height: auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #DEDEDE;
  position: absolute;
  width: 100%;
}
#AppLayout .ant-layout-header .ant-row {
  align-items: flex-start;
}
#AppLayout .ant-layout-header .filter-search{
  background-color: white;
}
#AppLayout .ant-layout-header .user-info{
  text-align: right;
}
#AppLayout .ant-layout-header .user-info .ant-avatar {
  border: 1px solid var(--color-primary);
  margin-right: 10px;
}
#AppLayout .content-layout{
  margin-top: 100px;
  padding: 0 50px 30px 50px;
}

#Header {
  background-color: var(--color-white);
}

#Header .ant-space {
  display: flex;
  justify-content: space-between;
}

#Header .ant-input-search {
  display: block;
}

#Header button {
  margin-left: 10px;
}
