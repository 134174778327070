#AdminInvoiceForm {

}

#AdminInvoiceForm .button-action {
  margin-top: 30px;
}
#AdminInvoiceForm .button-action .ant-btn{
  margin-right: 10px;
}

#AdminInvoiceForm .inv-identity {
  min-width: 300px;
  max-width: 700px;
}
#AdminInvoiceForm .inv-client {
  min-width: 300px;
  max-width: 700px;
}
#AdminInvoiceForm .selection-type-invoice {
  min-width: 300px;
  max-width: 700px;
}
#AdminInvoiceForm .inv-overview {
  margin-top: 30px;
  min-width: 300px;
  max-width: 400px;
}
#AdminInvoiceForm .inv-overview .item-overview {
  border-bottom: 1px solid rgb(193 193 193);
  padding: 10px 20px;
}
#AdminInvoiceForm .inv-overview .item-overview.total {
  background-color:rgb(193 193 193);
  border-bottom: 1px solid rgb(193 193 193);
}

#AdminInvoiceForm .inv-detail {
  margin-bottom: 20px;
}
#AdminInvoiceForm .inv-detail .content-inv-detail {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
#AdminInvoiceForm .inv-detail .content-inv-detail .ant-row {
  display: flex;
  flex-flow: nowrap;
}
#AdminInvoiceForm .inv-detail .content-inv-detail .ant-row .ppn-pph {
  text-align: center;
}
#AdminInvoiceForm .inv-detail .content-inv-detail .ant-row .ant-col .ppn-pph {
  text-align: center;
}
#AdminInvoiceForm .inv-detail .content-inv-detail .ant-row .ant-col .ant-form-item {
  margin-bottom: 10px;
}
#AdminInvoiceForm .inv-signature {
  min-width: 300px;
  max-width: 700px;
}
#AdminInvoiceForm .inv-attachments {
  min-width: 300px;
  max-width: 700px;
}
#AdminInvoiceForm .ant-form-item {
  margin-bottom: 15px;
}
#AdminInvoiceForm .section-field {
  margin-bottom: 20px;
}
